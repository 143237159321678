

<template>
    <div class="template-page yellow-step">
        <basic-page :pagedata="this.pageData" :bannerimg="introimg">
            <div class="person-header step-big">
                <div class="profile-pic" v-if="leaderData.attributes">
                    <router-link :to="personLink(leaderData)">
                    <div class="picture-wrapper">
                        <img :src="this.profilePic" alt="profile picture">
                    </div>
                    </router-link>
                </div>
                
                <div class="content angle dark-angle">
                    <div class="info">
                        <div class="label">
                            <div class="name" v-if="this.leaderData.attributes">
                                {{ this.leaderData.attributes.field_first_name + ' ' + this.leaderData.attributes.field_last_name }}
                            </div>
                        </div>
                        <div class="contacts">
                            <div v-if="this.leaderData.attributes" class="email">{{ this.leaderData.attributes.field_email[0] }}</div>
                            <div v-if="this.leaderData.attributes" class="phone">{{ this.leaderData.attributes.field_phone }}</div>
                        </div>
                    </div>
                    <div class="bio" v-if="this.leaderData.attributes && this.leaderData.attributes.field_person_bio" v-html="this.leaderData.attributes.field_person_bio.processed"></div>
                </div>
            </div>
            
            <div v-if="this.ruData.attributes && this.ruData.attributes.field_ru_description" 
                v-html="this.ruData.attributes.field_ru_description.processed"></div>
            <br><br>

            <h2 v-if="membersData.length>0">OTHER MEMBERS</h2>
            <div class="other-members people" v-if="membersData.length>0">
                <router-link  v-for="(member, index) in this.membersData" :key="'member' + index" :to="personLink(member)">
                    <person-tile :personData="member"></person-tile>
                </router-link>
            </div>
        </basic-page>
    </div>
</template>

<script>
import { fetchNodes, fetchSingleNode } from '../../libs/drupalClient';
import BasicPage from '../BasicPage.vue'
import PersonTile from '../../components/PersonTile.vue'
import { detailsLink } from '../../libs/utils'

export default {
  components: { BasicPage, PersonTile },
    name: 'person',
    data: () => {
        return {
            ruData: [],
            leaderData: {},
            membersData: [],
            pageData: {
                title: '',
                body: [{
                    summary: ''
                }]
            },
            introimg: '',
            introimgbannerID: 'c5964072-9161-4b55-9170-6d473ea70685'

        }
    },
    props: {
    },
    methods: {
        personLink(p) {
            return detailsLink('person', p);
        },
        fetchRU() {
            fetchNodes('research_unit', {
                filters: [{
                    key: 'drupal_internal__nid',
                    value: this.ruNID
                }],
                include: ['field_ru_leader_ref', 'field_ru_member_ref']
            }).then(res => {
                this.ruData = res[0]
            
                //console.log('ru', this.ruData)
                this.pageData.title = this.ruData.attributes.title
                this.pageData.body[0].summary = this.ruData.attributes.field_ru_description.summary//value
            })
        },
        fetchPeople() {
            fetchSingleNode('page', this.introimgbannerID, {
                include: ['field_banner_top']
            }).then(res => {
                this.introimg = process.env.VUE_APP_ENDPOINT + res[0].field_banner_top.attributes.uri.url
            })

            fetchNodes('person', {
                include: ['field_person_photo', 'field_person_position']
            }).then(res => {
                res.forEach(p => {
                    var count = 0;
                    if(this.ruData.field_ru_leader_ref.id == p.id)
                        this.leaderData = p;
                    
                    else if(count<this.ruData.field_ru_member_ref.length) {
                        this.ruData.field_ru_member_ref.forEach(m => { 
                            if(m.id == p.id) {
                                this.membersData.push(p)
                                count++;
                            }
                        })
                    }
                })

                //console.log('leader', this.leaderData)
                //console.log('members', this.membersData)
            })

            this.membersData = this.membersData.sort((a,b) => {
                return a.attributes.field_last_name>b.attributes.field_last_name ? 1 : -1
            })
        }
    },
    computed: {
        ruNID() {
            //console.log(this.$route.params)
            return this.$route.params.nid//.split('-').slice('-1')[0];
        },
        profilePic() {
            if(this.leaderData.field_person_photo) 
                return (process.env.VUE_APP_ENDPOINT + this.leaderData.field_person_photo.attributes.uri.url)
            else 
                return '/placeholder-people.svg';
        }
    },
    mounted() {
        this.fetchRU()
    },
    watch: {
        ruData() {
            this.fetchPeople()
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";

h2 {
    color: $secondaryColor;
}



.person-header {
    background: $mainColor;
    .profile-pic {
        position: relative;
        .picture-wrapper {
            max-width: 100%;
            position: relative;
        }
    }

    .content {
        .info {
            border-bottom: 2px #eee solid;
            display: flex; display: -webkit-flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 992px) {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }
            
            .contacts {
                display: flex; display: -webkit-flex;
                flex-direction: row;
                padding-top: .5em;
                
                .email {
                    color: #fff;
                    margin-right: 20px;
                    font-weight: 500;
                }
                .phone {
                    color: $secondaryColor;
                    font-weight: 500;
                }
            }

            .label .name {
                font-size:2.375rem;
                font-weight: 400;
            }
        }
    }
}

.other-members {
    /* display: flex; display: -webkit-flex;
    flex-direction: row;
    flex-wrap: wrap; */
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px 0;
    .person-wrapper {
        margin: 20px 0;
    }
}

.person-header .profile-pic::after {
    content: 'RESEARCH GROUP LEADER';
    background: #fff;
    color: $secondaryColor;
    font-weight: 700;
    padding: .5em 1em;
    width: 100px;
    display: block;
    position: absolute;
    bottom: 0;
}

.angle::after {
    top: unset;
}

@media (max-width:768px) {
    .other-members {
        a {
            width: 100%;
            //max-width: 300px;
        }
    }
}

@media(max-width: 1200px) {
    .other-members { grid-template-columns: repeat(3, 1fr); max-width: unset; }
}

@media(max-width: 992px) {
    .other-members { grid-template-columns: repeat(2, 1fr); max-width: unset; }
}

@media(max-width: 576px) {
    .other-members { grid-template-columns: 1fr; max-width: unset; }
}


</style>